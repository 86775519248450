import React from 'react';
import * as S from './button.styles';

const Button = (props) => {
    const {margin, ...otherProps} = props;
    return (
        <S.CustomButtonContainer margin={margin}>
            <S.CustomButton {...otherProps} />
        </S.CustomButtonContainer>
    )
}

export default Button
