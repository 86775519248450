import React from 'react';
// import FadeLoader from "react-spinners/FadeLoader";
import { LoaderContainer } from './loader.styles';

const Loader = (props) => {
    return (
        <LoaderContainer top={props.top} left={props.left}>
            {/* <FadeLoader color="#000" loading={props.loading} margin="1" height="13" size={150} /> */}
        </LoaderContainer>
    )
}

export default Loader
