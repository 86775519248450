import { createGlobalStyle } from 'styled-components';
import RubikRegular from "assets/fonts/Rubik-Regular.ttf";
import RubikMedium from "assets/fonts/Rubik-Medium.ttf";
import RubikLight from "assets/fonts/Rubik-Light.ttf";
import ExoRegular from "assets/fonts/Exo-Regular.ttf";
import ExoSemiBold from "assets/fonts/Exo-SemiBold.ttf";
 
const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'RubikRegular';
        src: url(${RubikRegular}) format('truetype')
    }
    @font-face {
        font-family: 'RubikMedium';
        src: url(${RubikMedium}) format('truetype')
    }
    @font-face {
        font-family: 'RubikLight';
        src: url(${RubikLight}) format('truetype')
    }
    @font-face {
        font-family: 'ExoRegular';
        src: url(${ExoRegular}) format('truetype')
    }
    @font-face {
        font-family: 'ExoSemiBold';
        src: url(${ExoSemiBold}) format('truetype')
    }
    body {
        margin: 0;
        padding: 0;
        min-width: 1024px;
        margin: auto;
        background: #F5FAF9;
        font-family: RubikMedium, Georgia;
        font-weight: 500;
        overflow-x: hidden;
    }
`;
 
export default GlobalStyles;