import ApiService from "./index";

const BASE_URL = () => {
    if(process.env.REACT_APP_ENV === "development") return process.env.REACT_APP_BASE_URL_DEVELOPMENT;
    else if(process.env.REACT_APP_ENV === "staging") return process.env.REACT_APP_STAGING_URL;
    else if(process.env.REACT_APP_ENV === "production") return process.env.REACT_APP_PRODUCTION_URL;
}

console.log(BASE_URL(),"BASE_URL")
const client = new ApiService({ baseURL: BASE_URL() });
const ApiCollections = {
  // *********** Auth Controller ********

  signIn: (params) => client.imgPost("/api/webLogin", params),
};

export default ApiCollections;
