import AuthActionTypes from 'redux/auth/auth.types'

  export const signInRequest = emailAndPassword => ({
    type: AuthActionTypes.SIGN_IN_REQUEST,
    payload: emailAndPassword
  });
  
  export const signInSuccess = user => ({
    type: AuthActionTypes.SIGN_IN_SUCCESS,
    payload: user,
  });
  
  export const signInFailure = error => ({
    type: AuthActionTypes.SIGN_IN_FAILURE,
    payload: error
  });

  export const loadingStart = () => ({
    type: AuthActionTypes.LOADING_START,
  });

  export const loadingStop = () => ({
    type: AuthActionTypes.LOADING_STOP,
  });
  
  export const checkUserSession = () => ({
    type: AuthActionTypes.CHECK_USER_SESSION
  });
  
  export const signOutRequest = () => ({
    type: AuthActionTypes.SIGN_OUT_REQUEST
  });
  
  export const signOutSuccess = () => {
    localStorage.removeItem("token");
    return ({
    type: AuthActionTypes.SIGN_OUT_SUCCESS
  })};
  
  export const signOutFailure = error => ({
    type: AuthActionTypes.SIGN_OUT_FAILURE,
    payload: error
  });