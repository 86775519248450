import { takeLatest, put, all, call } from 'redux-saga/effects';

import ApiCollections from 'configs/services/apiCollections'

import AuthActionTypes from 'redux/auth/auth.types';

import {
  loadingStart,
  signInSuccess,
  signInFailure,
} from './auth.actions';

/*************************************************/

export function* signIn({ payload }) {
  yield put(loadingStart());
  try {
    const response = yield call(ApiCollections.signIn, payload);
    console.log(response,payload,"payload")
    window.localStorage.setItem("token", response.data.token);
    yield put(signInSuccess(response.data));
  } catch (error) {
    yield put(signInFailure(error));
  }
}

/*************************************************/

export function* isUserAuthenticated() {
  // try {
  //   const userAuth = yield getCurrentUser();
  //   if (!userAuth) return;
  //   yield getSnapshotFromUserAuth(userAuth);
  // } catch (error) {
  //   yield put(signInFailure(error));
  // }
}

/*************************************************/

export function* signOut() {
  // try {
  //   // yield call(ApiCollections.signOut, payload);
  //   yield put(loadingStart());
  //   yield put(signOutSuccess());
  //   yield put(loadingStop());
  // } catch (error) {
  //   yield put(signOutFailure(error));
  //   yield put(loadingStop());
  // }
}

/*************************************************/

export function* authSagas() {
  yield all([
    yield takeLatest(AuthActionTypes.SIGN_IN_REQUEST, signIn),
    yield takeLatest(AuthActionTypes.SIGN_OUT_REQUEST, signOut),
    yield takeLatest(AuthActionTypes.CHECK_USER_SESSION, isUserAuthenticated),
  ]);
}

/*************************************************/