import styled from "styled-components";
import Button from 'components/formComponents/button/Button';

export const TopbarContainer = styled.nav`
    position: relative;
`;

export const TopbarImage = styled.img`
    width: 104%;
    margin: ${props => props.progress ? "0% 0 0 -3%" : "-4% 0 0 -3%"};
`;

export const TopbarLogo = styled.img`
    position: absolute;
    z-index: 10;
    margin: auto 20px;
    left: 4%;
    top: ${props => props.progress ? "55px" : "24px"};

    @media only screen and (min-width: 1150px) {
        top: ${props => props.progress ? "5rem" : "2rem"};
    }
`;

export const FurtherButton = styled(Button)`
    position: absolute;
    line-height: 20px;
    margin: auto;
    top: ${props => props.progress ? "75px" : "35px"};
    right: 45px;

    @media only screen and (min-width: 1150px) {
        top: ${props => props.progress ? "6rem" : "3rem"};
    }
`; 

export const BackButton = styled(Button)`
    position: absolute;
    line-height: 20px;
    margin: auto;
    top: ${props => props.progress ? "75px" : "35px"};
    right: 160px;
    background-color: transparent;
    opacity: .7;

    @media only screen and (min-width: 1150px) {
        top: ${props => props.progress ? "6rem" : "3rem"};
    }
`; 