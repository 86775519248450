import React from "react";
import { Formik } from "formik";
import { object, string } from "yup";
import { useDispatch, useSelector } from "react-redux";

import Topbar from "components/topbar/Topbar";
import Card from "components/card/Card";
import Input from "components/formComponents/input/Input";
import Button from "components/formComponents/button/Button";
import * as S from "./login.styles";
import Loader from "components/loader/Loader";
import ErrorMessage from "components/formComponents/errorMessage/ErrorMessage";

// Redux
import * as AuthActions from "redux/auth/auth.actions";

const Login = () => {
  const dispatch = useDispatch();
  const authenticated = useSelector((state) => state.auth.token);

  if (authenticated) {
    window.location.assign("https://foodiary.coach/dashboard/")
  }

  const loading = useSelector((state) => state.auth.loading);

  const initialValues = {
    email: "",
    password: "",
    client_secret: "DgVOyRUhsmVT741dyCqUvrMk1GMX3afx7U7XRbvG",
    client_id: "2",
    grant_type: "password",
  };

  const validationSchema = object().shape({
    email: string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
      password:string("Enter your password")
      .required("Password is required"),
  });

  const onFormSubmit = (values, { resetForm }) => {

    const formData = {
        grant_type:"password",
        email:values.email,
        password:values.password,
        client_secret:"DgVOyRUhsmVT741dyCqUvrMk1GMX3afx7U7XRbvG",
        client_id:2
    }
    dispatch(AuthActions.signInRequest(formData))
    // window.location.assign("https://foodiary.coach/dashboard/")

    resetForm();
  };

  return (
    <>
      <Topbar />
      <S.LoginContainer>
        <Card width="400px" height="auto" margin="auto" textAlign="center">
          <S.LoginInnerContainer>
            <S.LoginHeading>Login</S.LoginHeading>
            <S.LoginSubHeading>Foodiary Customer</S.LoginSubHeading>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onFormSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isValid,
                dirty,
              }) => (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Input
                    filled
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                  />
                  <ErrorMessage error={errors.email} />
                  <Input
                    filled
                    type="password"
                    placeholder="password"
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                  />
                  <ErrorMessage error={errors.password} />
                  <Button
                    color="rgba(248, 164, 146, 1)"
                    margin="20px 0 0 0"
                    type="submit"
                  >
                    Login
                  </Button>
                  <Loader loading={loading} top="-135px" />
                </form>
              )}
            </Formik>
          </S.LoginInnerContainer>
        </Card>
      </S.LoginContainer>
    </>
  );
};

export default Login;
