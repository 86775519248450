import styled from "styled-components";

export const LoginContainer = styled.div`
  width: 950px;
  margin: 100px auto auto auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 130px;
`;

export const LoginInnerContainer = styled.div`
  padding: 45px 0;
`;

export const LoginHeading = styled.div`
  margin: 0 0 10px 0;
  color: #1c9d87;
  font-size: 14px;
`;

export const LoginSubHeading = styled.div`
  color: #142c1f;
  font-size: 22px;
  margin-bottom: 30px;
`;
