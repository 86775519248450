import React from "react";
import { useSelector } from "react-redux";
import * as S from "./topbar.styles";
import { useHistory } from "react-router-dom";

import ProgressBar from "components/progressBar/ProgressBar";

// Images
import topbar from "assets/images/topbar.svg";
import topbarLogo from "assets/images/logo.svg";
import rightArrow from "assets/images/rightArrow.png";

const Topbar = (props) => {
  const { prev, next } = props;
  const history = useHistory();
  const authenticated = useSelector((state) => state.auth.token);

  const handleLinkPosition = (upcomingPage) => {
    let newCurrentLinkPosition = props.arrayData.indexOf(upcomingPage);
    let newPrevLink = props.arrayData[newCurrentLinkPosition - 1];
    let newNextLink = props.arrayData[newCurrentLinkPosition + 1];
    props.setPrevLink(newPrevLink);
    props.setNextLink(newNextLink);
  };
  const handlePrev = () => {
    if (props.arrayData) handleLinkPosition(prev);
    history.push(prev);
  };
  const handleNext = () => {
    if (props.arrayData) handleLinkPosition(next);
    history.push(next);
  };

  return (
    <div>
      <S.TopbarContainer>
        {props.progress && <ProgressBar progress={props.progress} />}
        <S.TopbarLogo progress={props.progress} src={topbarLogo} alt="Logo" />
        <S.TopbarImage src={topbar} alt="Top Bar" progress={props.progress} />
        {authenticated && prev && (
          <S.BackButton
            color="rgba(248, 164, 146, 1)"
            progress={props.progress}
            onClick={handlePrev}
            back
          >
            Zurück
          </S.BackButton>
        )}
        {authenticated && next && (
          <S.FurtherButton
            color="rgba(248, 164, 146, 1)"
            progress={props.progress}
            onClick={handleNext}
          >
            Weiter <img src={rightArrow} alt="Right Arrow" />
          </S.FurtherButton>
        )}
      </S.TopbarContainer>
    </div>
  );
};

export default Topbar;
