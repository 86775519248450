
const auth_types = {
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    CHECK_USER_SESSION: 'CHECK_USER_SESSION',

    LOADING_START: 'LOADING_START',
    LOADING_STOP: 'LOADING_STOP',

    SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',

    SIGN_OUT_REQUEST: 'SIGN_OUT_REQUEST',
    SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
    SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
}

export default auth_types;
