import styled from "styled-components";

export const CustomButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: ${props => props.margin};
`;

export const CustomButton = styled.button`
    background-color: ${props => props.outlined ? "white" : props.color};
    background-image: ${props => props.gradient ? props.gradient : 'none'};
    color: ${props => props.outlined ? props.color : "white"};
    border: ${props => props.outlined ? `2px solid ${props.color}` : "none"};
    padding: 12px 20px;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
`;